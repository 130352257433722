import { useEffect } from 'react'
import { IPrimisVideoAdProps } from './interfaces'

declare global {
  interface Window {
    googletag: any
  }
}

const PrimisVideoAd = ({ id }: IPrimisVideoAdProps) => {
  useEffect(() => {
    let definedslot: any = null
    if (window.googletag) {
      window.googletag.cmd.push(function () {
        definedslot = window.googletag
          .defineSlot(
            '/1012891/mapsinventory/List_View_Ads',
            [
              [300, 250],
              [320, 480],
            ],
            `div-gpt-ad-1726688341035-0-${id}`
          )
          .addService(window.googletag.pubads())
        console.log(definedslot)
        window.googletag?.display(`div-gpt-ad-1726688341035-0-${id}`)
      })
    }
    return () => {
      window.googletag.cmd.push(function () {
        const isDestroyed = window.googletag.destroySlots([definedslot])
        console.log(
          'slot destroyed',
          `div-gpt-ad-1726688341035-0-${id}`,
          isDestroyed
        )
      })
    }
  }, [])

  return (
    <div
      id={`div-gpt-ad-1726688341035-0-${id}`}
      className={'gptads__cover'}
      style={{
        minWidth: '300px',
        minHeight: '100px',
      }}
    />
  )
}

export default PrimisVideoAd
