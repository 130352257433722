import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { updateSelectedLocation } from '../../../store/search/actions'
import { openSelectedUrl } from '../../../store/details/slice'

import { updateFirstLoad } from '../../../store/search/slice'
import ListingsView from './ListingsView'

const mapStateToProps = (state: RootState) => {
  return {
    currentSelection: state.details.currentSelection,
    recents: state.details.recents,
    showTab: state.details.showTab,
  }
}

const ConnectedListingsView = connect(mapStateToProps, {
  updateFirstLoad,
  updateSelectedLocation,
  openSelectedUrl,
})(ListingsView)

export default ConnectedListingsView
