import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '..'

const selectCurrentSelection = (state: RootState) => state.search.listings

const selectSelectedLocation = (state: RootState) =>
  state.search.initialSelectedLocation
const selectFirstLoad = (state: RootState) => state.search.isFirstLoad

export const getSelectedLocationName = createSelector(
  selectCurrentSelection,
  selectSelectedLocation,
  selectFirstLoad,
  (listings, initialSelectedLocation, isFirstLoad) => {
    const firstListing = listings.filter(
      (e: any) => e.id === initialSelectedLocation
    ) as any

    if (firstListing.length === 1) {
      return (
        firstListing[0].name ||
        (firstListing[0].address.numberAndStreet
          ? firstListing[0].address.numberAndStreet.substring(0, 15)
          : '')
      )
    } else {
      return ''
    }
  }
)
