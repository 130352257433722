import { ISellerTypeID } from 'mhbo-js'

export const pricingOptionsRent = {
  heading: 'monthly rent',
  default: 'Any Price',
  values: [
    { label: 'Under $500', value: '0,500', id: '1' },
    { label: 'Under $750', value: '0,750', id: '2' },
    { label: 'Up to $1K', value: '0,1000', id: '3' },
    { label: 'Over $1K', value: '1000,999999', id: '4' },
  ],
}

export const pricingOptionsBuy = {
  heading: 'price',
  default: 'Any Price',
  values: [
    { label: 'Up to $50K', value: '0,50000', id: '5' },
    { label: 'Up to $75K', value: '0,75000', id: '6' },
    { label: 'Up to $100K', value: '0,100000', id: '7' },
    { label: 'Over $100K', value: '100000,999999', id: '8' },
  ],
}

export const pricingOptionsCommunities = {
  heading: 'community type',
  default: 'Any Type',
  values: [
    { label: '55+', value: '2', id: '9' },
    { label: 'All-Ages', value: '1', id: '10' },
    { label: 'ROC', value: 'true', id: '11' },
    { label: 'Pets OK', value: 'true', id: '12' },
  ],
}

export const moreFiltersCommunityLocationType = {
  subHeading: 'Community/Location Type',
  nameArray: [
    {
      name: 'ageQualified',
      text: 'age qualified (55+)',
      key: 'ageRestrictionType',
      value: '2',
    },
    {
      name: 'mhrvResort',
      text: 'MHRV Resort',
      key: 'isMhrv',
      value: 'true',
    },
    {
      name: 'familyFriendly',
      text: 'family friendly',
      key: 'ageRestrictionType',
      value: '1',
    },
    {
      name: 'residentOwned',
      text: 'ROC (Resident Owned)',
      key: 'isResidentOwned',
      value: 'true',
    },
    {
      name: 'petFriendly',
      text: 'pet friendly',
      key: 'withPetFriendly',
      value: 'true',
    },
    {
      name: 'tinyHouse',
      text: 'tiny house',
      key: 'isTinyHouseCommunity',
      value: 'true',
    },
  ],
}

export const moreFiltersBuyLocationType = {
  subHeading: 'Community/Location Type',
  nameArray: [
    {
      name: 'ageQualified',
      text: 'age qualified (55+)',
      key: 'ageRestrictionType',
      value: '2',
    },
    {
      name: 'mhrvResort',
      text: 'MHRV Resort',
      key: 'fields',
      value: 'mhrvResort',
    },
    {
      name: 'familyFriendly',
      text: 'family friendly',
      key: 'ageRestrictionType',
      value: '1',
    },
    {
      name: 'residentOwned',
      text: 'ROC (Resident Owned)',
      key: 'isResidentOwned',
      value: 'true',
    },
    {
      name: 'onDealerLot',
      text: 'on dealer lot',
      key: 'locationStatusId',
      value: '5',
    },
    {
      name: 'petFriendly',
      text: 'pet friendly',
      key: 'withPetFriendly',
      value: 'true',
    },
    {
      name: 'privateProperty',
      text: 'private property',
      key: 'locationStatusId',
      value: '2',
    },
    {
      name: 'tinyHouse',
      text: 'tiny house',
      key: 'fields',
      value: 'tinyHouse',
    },
  ],
}

export const moreFiltersBuySellerType = {
  subHeading: 'Seller type',
  nameArray: [
    { name: 'owner', text: 'owner', value: ISellerTypeID.Owner },
    { name: 'dealer', text: 'dealer', value: ISellerTypeID.Dealer },
    { name: 'agentRealtor', text: 'agent/realtor', value: ISellerTypeID.Agent },
    {
      name: 'lenderBankRepo',
      text: 'lender/Bank repo',
      value: ISellerTypeID.Repo,
    },
  ],
}
