import { createAsyncThunk } from '@reduxjs/toolkit'
import { loadToken } from '../user/actions'
import { ICommunity, IEntityType, IMHBOListing, IMobileHome } from 'mhbo-js'
import { apiClient } from '../mhboConfig'
import { RootState } from '..'
import {
  IUpdateCurrentSelection,
  IUpdateSelectedDetails,
  IUpdateSelectedDetailsParams,
} from './types'
import { updateIdsCache } from './slice'

export const updateCurrentSelection = createAsyncThunk<
  IUpdateCurrentSelection,
  IMHBOListing[],
  { state: RootState }
>('details/updateCurrentSelection', async (selectedList, thunkAPI) => {
  thunkAPI.dispatch(loadToken())
  // check for list in details
  const { details } = thunkAPI.getState()
  const { byIds, ids } = details
  const missingListings = selectedList.filter(
    (listing) => !ids.includes(listing.id)
  )

  const listingsIds = selectedList
    .filter((listing) => ids.includes(listing.id))
    .map((e) => e.id)
  let listings: IMHBOListing[] = byIds.filter((e: IMHBOListing) =>
    listingsIds.includes(e.id)
  )
  // call async api calls for missing details
  const missingHomes = missingListings.filter(
    (e) => e.entityType === IEntityType.Home
  )
  const missingCommunities = missingListings.filter(
    (e) => e.entityType !== IEntityType.Home
  )
  if (missingHomes.length > 0) {
    const homeListings = (await apiClient!.homes.byIds(
      missingHomes.map((e) => e.id)
    )) as IMHBOListing[]
    thunkAPI.dispatch(updateIdsCache(homeListings))
    listings = [...listings, ...homeListings]
  }
  if (missingCommunities.length > 0) {
    const communityListings = (await apiClient!.communities.byIds(
      missingCommunities.map((e) => e.id)
    )) as IMHBOListing[]
    thunkAPI.dispatch(updateIdsCache(communityListings))
    listings = [...listings, ...communityListings]
  }
  return { currentSelection: listings as Array<ICommunity | IMobileHome> }
})

export const updateSelectedDetail = createAsyncThunk<
  IUpdateSelectedDetails,
  IUpdateSelectedDetailsParams,
  { state: RootState }
>('details/updateSelectedDetais', async ({ detailId, x, y }, thunkAPI) => {
  const { details, search } = thunkAPI.getState()
  const { listings } = search
  thunkAPI.dispatch(loadToken()) // check for list in details
  const { byIds } = details
  const isListingMissing = byIds.filter((e: any) => e.id === detailId)
  if (isListingMissing.length === 0) {
    const missingListing = listings.filter((e: any) => e.id === detailId)[0]
    let newListing
    if (missingListing.entityType === IEntityType.Home) {
      newListing = (await apiClient!.homes.byIds([detailId])) as IMHBOListing[]
    } else {
      newListing = (await apiClient!.communities.byIds([
        detailId,
      ])) as IMHBOListing[]
    }
    thunkAPI.dispatch(updateIdsCache(newListing))
  }
  return {
    clientX: x,
    clientY: y,
    selectedDetail: detailId,
  }
})
