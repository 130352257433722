import { FunctionComponent } from 'react'
interface IProps {
  pointCount: number
  onClick?: (e: any) => void
  lat?: number
  lng?: number
}

const ClusterMarker: FunctionComponent<IProps> = ({
  pointCount,
  onClick,
}: IProps) => {
  return (
    <span
      className="numberCircle"
      onClick={onClick}
      onTouchEnd={(e) => e.stopPropagation()}
    >
      <span>{pointCount}</span>
    </span>
  )
}
export default ClusterMarker
