const getBaseUrl = () => {
  let hostname = window.location.hostname.replace(`maps.`, ``)
  if (hostname === `mhbo.com`) {
    hostname = `www.mhbo.com`
  }
  if (hostname === `localhost`) return `https://staging.mhbo.com`
  return `${window.location.protocol}//${hostname}`
}

export default getBaseUrl
