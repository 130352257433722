import { FunctionComponent } from 'react'
import { Col } from 'reactstrap'
import MarkerIcon from '../../common-ui/MarkerIcon'
import { IMarkerCellProps } from './interfaces'
import { ISellerTypeID } from 'mhbo-js'
import createGTM from '../../../helpers/gtmevent'
const MarkerCell: FunctionComponent<IMarkerCellProps> = ({
  description,
  color,
  updateQuery,
}: IMarkerCellProps) => {
  const value = (description: string) => {
    if (description === 'For Sale') return 1
    if (description === 'For Rent') return 2
    if (description === 'Dealer') return 4
    if (description === 'Community') return 3
    else return 0
  }

  const handleChange = (description: string) => {
    const listingType = value(description)
    let entityType = ''
    let listingTypeIds: string[] = []
    let isDealerType = 'false'
    if (listingType === 1) {
      entityType = '1'
      listingTypeIds = ['1']
    } else if (listingType === 2) {
      entityType = '1'
      listingTypeIds = ['2']
    } else if (listingType === 3) {
      entityType = '2'
    } else if (listingType === 4) {
      entityType = '2'
      isDealerType = 'true'
    }
    createGTM({
      action: `legend, click "${description}"`,
    })

    updateQuery({
      ageRestrictionType: [],
      entityType,
      isDealer: isDealerType,
      isMhrv: '',
      isResidentOwned: '',
      isTinyHouseCommunity: '',
      listingTypeIds,
      locationStatusId: [],
      maxPrice: '',
      minPrice: '',
      numBathrooms: [],
      numBedrooms: [],
      sellerTypeIds: [
        `${ISellerTypeID.Owner}`,
        `${ISellerTypeID.Repo}`,
        `${ISellerTypeID.Agent}`,
      ],
      withPetFriendly: '',
    })
  }
  return (
    <Col
      md={6}
      style={{ color, padding: 0, cursor: 'pointer' }}
      onClick={() => {
        handleChange(description)
      }}
    >
      <MarkerIcon color={color} />
      <span className="legend__marker_cell_text">{description}</span>
    </Col>
  )
}

export default MarkerCell
