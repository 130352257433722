import { useAppSelector } from '../../../store/hooks'

const BUY_HEADER = `Sale`
const RENT_HEADER = `Rental`

interface IProps {
  currentLocation: string
  entityType: string | undefined
  isDealer: string | undefined
  listingTypeIds: string[] | undefined
}

const ListingHeader = ({
  currentLocation,
  entityType,
  isDealer,
  listingTypeIds,
}: IProps) => {
  const listingsCount = useAppSelector(
    (state) => state.search.boundslistings.length
  )
  const filtered = useAppSelector((state) => state.search.filtered)

  let typeOfSearch = ''
  if (entityType === '2') {
    if (isDealer === 'true') {
      typeOfSearch = 'Dealer'
    } else {
      typeOfSearch = 'Community'
    }
  }
  if (entityType !== '2') {
    if (listingTypeIds) {
      if (listingTypeIds.includes('1') || listingTypeIds.includes('3')) {
        typeOfSearch = BUY_HEADER
      } else {
        typeOfSearch = RENT_HEADER
      }
    }
  }
  return (
    <div className="sidebar__listingcount">
      {currentLocation.length > 0
        ? `${listingsCount} ${typeOfSearch} Listings ${
            filtered ? `in` : `near`
          } ${currentLocation
            .replace(', USA', '')
            .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())} `
        : ''}
    </div>
  )
}

export default ListingHeader
