declare var window: any

const createGTM = ({
  category = '',
  action = '',
  label = '',
  value = '',
}: any) => {
  window.dataLayer.push({
    event: 'react-event',
    eventProps: {
      category,
      action,
      label,
      value,
    },
  })
}

export default createGTM
