import { ICommunity, IEntityType, IMobileHome } from 'mhbo-js'
import React, { Suspense } from 'react'
import { IProps } from './interfaces'
import { useAppSelector } from '../../../store/hooks'
import { getFavoriteId } from '../../../store/user/selectors'

const CommunityPreviewCell = React.lazy(() => import('./CommunityPreviewCell'))
const DealerPreviewCell = React.lazy(() => import('./DealerPreviewCell'))
const HomePreviewCell = React.lazy(() => import('./HomePreviewCell'))

const ListingPreviewCell: React.FC<IProps> = (props: IProps) => {
  const { listing, onClick, onMouseOver, onMouseOut, isLazy } = props
  const favid = useAppSelector((state) => getFavoriteId(state, listing.id))

  const { entityType } = listing
  if (entityType === IEntityType.Community) {
    return (
      <Suspense fallback={null}>
        <CommunityPreviewCell
          communityListing={listing as ICommunity}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={onClick}
          isFavorite={favid !== -1}
          favid={favid}
          isLazy={isLazy}
        />
      </Suspense>
    )
  } else if (entityType === IEntityType.Dealer) {
    return (
      <Suspense fallback={null}>
        <DealerPreviewCell
          dealerListing={listing as ICommunity}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={onClick}
          isLazy={isLazy}
        />
      </Suspense>
    )
  } else
    return (
      <Suspense fallback={null}>
        <HomePreviewCell
          homeListing={listing as IMobileHome}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={onClick}
          isFavorite={favid !== -1}
          favid={favid}
          isLazy={isLazy}
        />
      </Suspense>
    )
}

export default ListingPreviewCell
