interface IProps {
  lastQuery: {
    center: {
      lat: number
      lng: number
    }
    radius: number
  }
  boundingBox: {
    NE: any
    SW: any
  }
  maps: any
}

function requiresRequery({ lastQuery, boundingBox, maps }: IProps) {
  const { NE, SW } = boundingBox
  const NW = new maps.LatLng(NE.lat(), SW.lng())
  const SE = new maps.LatLng(SW.lat(), NE.lng())
  const { center, radius } = lastQuery
  const { lat, lng } = center
  const oldCenter = new maps.LatLng(lat, lng)
  if (
    getDistance(oldCenter, NE, maps) > radius ||
    getDistance(oldCenter, SW, maps) > radius ||
    getDistance(oldCenter, NW, maps) > radius ||
    getDistance(oldCenter, SE, maps) > radius
  ) {
    return true
  }
  return false
}

function getDistance(pointA: any, pointB: any, maps: any): number {
  const distance = parseFloat(
    (
      maps.geometry.spherical.computeDistanceBetween(pointA, pointB) / 1609.344
    ).toFixed(0)
  )
  return distance
}

export { getDistance, requiresRequery }
