import { createSlice } from '@reduxjs/toolkit'
import type { IDetailsState } from './types'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IMHBOListing } from 'mhbo-js'

// Define the initial state using that type
const initialState: IDetailsState = {
  byIds: [],
  clientX: 0,
  clientY: 0,
  currentSelection: [],
  ids: [],
  selectedDetail: -1,
  recents: [],
  showTab: 0,
}

export const userSlice = createSlice({
  name: 'details',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateIdsCache: (state, action: PayloadAction<IMHBOListing[]>) => {
      const currentListingsIds = state.byIds.map((listing) => listing.id)
      const newListings = action.payload.filter(
        (listing) => !currentListingsIds.includes(listing.id)
      )
      const newIds = newListings.map((e) => e.id)
      return {
        ...state,
        byIds: [...state.byIds, ...newListings],
        ids: [...state.ids, ...newIds],
      }
    },
    removeSelectedDetail: (state) => {
      state.selectedDetail = -1
    },
    changeTab: (state, action) => {
      state.showTab = action.payload
    },
    openSelectedUrl: (state, action: PayloadAction<number>) => {
      const { byIds } = state

      const detailedListing: any = byIds.filter(
        (e: any) => e.id === action.payload
      )
      if (detailedListing.length > 0) {
        window.open(detailedListing[0].url, '_blank')

        return {
          ...state,
          recents: [
            detailedListing[0],
            ...state.recents.filter((e) => e.id !== detailedListing[0].id),
          ],
        }
      }
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addMatcher(
      (action) =>
        action.type.startsWith('details') && action.type.endsWith('fulfilled'),
      (state, action: any) => {
        return {
          ...state,
          ...action.payload,
        }
      }
    )
  },
})

export const {
  updateIdsCache,
  removeSelectedDetail,
  openSelectedUrl,
  changeTab,
} = userSlice.actions

export default userSlice.reducer
