import { createSlice } from '@reduxjs/toolkit'
import type { ILatLng, ISearchState, IFilterListingsResponse } from './types'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  updateActivePage,
  updateListings,
  updateTypedLocation,
} from './actions'
import { ISellerTypeID } from 'mhbo-js'

// Define the initial state using that type
const initialState: ISearchState = {
  bounds: null,
  boundslistings: [],
  currentPageNumber: 1,
  isModalOpen: false,
  isModalShareOpen: false,
  lastCenterLat: 0,
  lastCenterLng: 0,
  lastCenterRadius: 50,
  lastParams: '',
  listings: [],
  searchIndex: 0,
  initialSelectedLocation: -1,
  selectedLocation: -1,
  shouldRequery: false,
  streetMapData: {},
  typedLocation: '',
  isFirstLoad: true,
  filtered: false,
  triangle: [],
  boundsFilterIndex: 0,
}

export const searchSlice = createSlice({
  name: 'search',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateModalState: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload
    },
    updateModalShareState: (state, action: PayloadAction<boolean>) => {
      state.isModalShareOpen = action.payload
    },
    updateLatLng: (state, action: PayloadAction<ILatLng>) => {
      state.lastCenterLat = action.payload.lat
      state.lastCenterLng = action.payload.lng
    },
    updateFirstLoad: (
      state,
      action: PayloadAction<boolean | null | undefined>
    ) => {
      state.isFirstLoad = action.payload ?? false
    },
    updateRequeryStatus: (state, action: PayloadAction<boolean>) => {
      state.shouldRequery = action.payload
    },
    updateTriangle: (state, action: PayloadAction<any>) => {
      state.triangle = action.payload
    },
    updateBounds: (state, action: PayloadAction<IFilterListingsResponse>) => {
      if (action.payload.boundsFilterIndex === state.boundsFilterIndex) {
        let newboundslistings = [
          ...state.boundslistings,
          ...action.payload.boundslistings,
        ]

        try {
          if (
            state.boundslistings[0]?.id === state.initialSelectedLocation &&
            state.boundslistings.length === 1 &&
            action.payload.boundslistings.length > 0
          ) {
            newboundslistings = action.payload.boundslistings
          }
        } catch (error) {
          console.log({ error })
        }
        return {
          ...state,
          boundslistings: newboundslistings,
        }
      } else if (action.payload.boundsFilterIndex > state.boundsFilterIndex) {
        const newlistings = action.payload.boundslistings
        state.boundslistings = newlistings
        state.boundsFilterIndex = action.payload.boundsFilterIndex
      } else return state
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(updateListings.fulfilled, (state, action) => {
        const { listings, newParams, searchIndex } = action.payload
        if (searchIndex < state.searchIndex) {
          return state
        }
        const params = newParams === '' ? {} : JSON.parse(newParams!)

        const {
          location,
          page,
          pageCount,
          radius: paramRadius,
          sellerTypeIds = [],
          ...checkParams
        } = params

        let showModal = true
        if (
          Object.keys(checkParams).length > 0 ||
          sellerTypeIds.filter(
            (e: any) =>
              ![
                `${ISellerTypeID.Owner}`,
                `${ISellerTypeID.Repo}`,
                `${ISellerTypeID.Agent}`,
              ].includes(e)
          ).length > 0
        ) {
          showModal = false
        }
        return {
          ...state,
          currentPageNumber: 1,
          isModalOpen: listings.length === 0 && showModal ? true : false,
          lastCenterRadius: paramRadius,
          lastParams: newParams === '' ? state.lastParams : newParams!,
          listings,
          searchIndex,
        }
      })
      .addCase(updateActivePage.fulfilled, (state, action) => {
        state.currentPageNumber = action.payload
      })
      .addCase(updateTypedLocation.fulfilled, (state, action) => {
        return {
          ...state,
          shouldRequery: true,
          ...action.payload,
        }
      })
      .addMatcher(
        (action) =>
          action.type.startsWith('search') &&
          action.type.endsWith('fulfilled') &&
          ![
            updateListings.fulfilled.type,
            updateActivePage.fulfilled.type,
            updateTypedLocation.fulfilled.type,
          ].includes(action.type),
        (state, action: any) => {
          return {
            ...state,
            ...action.payload,
          }
        }
      )
  },
})

export const {
  updateModalState,
  updateModalShareState,
  updateLatLng,
  updateFirstLoad,
  updateRequeryStatus,
  updateTriangle,
  updateBounds,
} = searchSlice.actions

export default searchSlice.reducer
