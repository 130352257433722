import FilterPanel from './components/FilterPanel'
import Helmet from 'react-helmet'
// CSS
import './styles/styles.scss'
import Button from '@mui/material/Button'
import { useState } from 'react'
// Router
import { BrowserRouter as Router } from 'react-router-dom'

// Redux
import { Provider } from 'react-redux'
import store from './store'

// Components
import PropertiesMap from './components/PropertiesMap/index'
import Sidebar from './components/Sidebar/Sidebar'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-5KFP',
}
TagManager.initialize(tagManagerArgs)

const App: React.FC = () => {
  const [showMap, setShowMap] = useState(true)

  return (
    <Provider store={store}>
      <Router>
        <Helmet>
          <script>
            {`window.googletag = window.googletag || {cmd: []};
      googletag.cmd.push(function() {      
        googletag.pubads().collapseEmptyDivs(true);
        googletag.enableServices();
      });`}
          </script>
        </Helmet>
        <div className="container__appShell">
          <FilterPanel />
          <div
            className="container__mapAndSidebar"
            style={{
              position: window.innerWidth < 768 ? 'relative' : 'initial',
            }}
          >
            <div
              className={
                window.innerWidth < 768
                  ? `container_mapcolumn_mobile`
                  : `container__mapcolumn`
              }
              id="map"
              style={{
                left: window.innerWidth < 768 ? (showMap ? '0' : '-120%') : '0',
              }}
            >
              <PropertiesMap showMap={showMap} />
            </div>
            <div
              className="container__sidebarcolumn"
              id="sidebar"
              style={{
                display:
                  window.innerWidth < 768
                    ? showMap
                      ? 'none'
                      : 'block'
                    : 'block',
              }}
            >
              <Sidebar />
            </div>
            <div className="container__toggleButton">
              <Button variant="contained" onClick={() => setShowMap(!showMap)}>
                {showMap ? `List` : `Map`}
              </Button>
            </div>
          </div>
        </div>
      </Router>
    </Provider>
  )
}

export default App
