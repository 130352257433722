import { ICommunity, IMHBOListing, IMobileHome } from 'mhbo-js'
import { FunctionComponent } from 'react'
import { Element } from 'react-scroll'
import PaginationComponent from '../../PaginationComponent/PaginationComponent'
import AdComponent from '../AdComponent'
import ListingPreviewCell from '../ListingPreviewCell/index'
import { Launch } from '@mui/icons-material'

interface IProps {
  currentSelection: Array<IMobileHome | ICommunity>
  updateFirstLoad: () => void
  updateSelectedLocation: (selectedLocation: number) => void
  recents: Array<IMobileHome | ICommunity>
  showTab: number
  openSelectedUrl: (id: number) => void
}

const ListingsView: FunctionComponent<IProps> = ({
  currentSelection,
  updateFirstLoad,
  updateSelectedLocation,
  showTab,
  recents,
  openSelectedUrl,
}: IProps) => {
  if (showTab === 1) {
    const selectedListing = currentSelection[0] as any
    if (selectedListing) {
      return (
        <>
          <Element name={`listing${selectedListing.id}`}>
            <ListingPreviewCell
              listing={selectedListing}
              isLazy={false}
              onClick={() => openSelectedUrl(selectedListing.id)}
            />
            <AdComponent id="selectedentity" />

            <div
              dangerouslySetInnerHTML={{
                __html:
                  selectedListing.description ||
                  selectedListing.propertyDescription,
              }}
            />
            <p
              className="listingCell__community_link"
              onClick={() => openSelectedUrl(selectedListing.id)}
            >
              See Details
              <Launch />
            </p>
          </Element>
        </>
      )
    }
  }
  const showSelection = showTab === 2 ? recents : currentSelection
  const adIndexArray = [
    2,
    currentSelection.length / 2,
    currentSelection.length - 3,
  ]
  return (
    <div>
      {showSelection.map((listing: IMHBOListing, index: number) => {
        return (
          <span key={listing.id}>
            {adIndexArray.includes(index) ? (
              <AdComponent
                key={`ad-${index}`}
                id={`ad-${adIndexArray.indexOf(index)}`}
              />
            ) : null}
            <Element name={`listing${listing.id}`}>
              <ListingPreviewCell
                listing={listing}
                isLazy={index > 3}
                onClick={() => openSelectedUrl(listing.id)}
                onMouseOver={() => {
                  updateFirstLoad()
                  updateSelectedLocation(listing.id)
                }}
                onMouseOut={() => updateSelectedLocation(-1)}
              />
            </Element>
          </span>
        )
      })}
      {showTab === 0 ? <PaginationComponent /> : null}
    </div>
  )
}

export default ListingsView
