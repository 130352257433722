import { FunctionComponent } from 'react'

interface IProps {
  color: string
}

const MarkerIcon: FunctionComponent<IProps> = ({ color }: IProps) => (
  <svg width="20" height="20">
    <defs>
      <filter
        id="f1"
        x="-40%"
        y="-40%"
        width="180%"
        height="180%"
        filterUnits="userSpaceOnUse"
      >
        <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
        <feOffset dx="5" dy="5" result="offsetblur" />
        <feOffset dx="-5" dy="-3" result="offsetblur" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.31 0"
        />

        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <circle
      r="5"
      fill={color}
      z-index="55"
      cx="10"
      cy="10"
      stroke="white"
      strokeWidth="1"
      style={{ filter: 'url(#f1)' }}
    />
  </svg>
)
export default MarkerIcon
