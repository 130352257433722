import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { Component } from 'react'
import { connect } from 'react-redux'
import { animateScroll } from 'react-scroll'
import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from 'reactstrap'
import { PAGE_COUNT } from '../../constants/paginationConstants'
import { updateActivePage } from '../../store/search/actions'
import getPageNumbers from './getPageNumbers'
import { RootState } from '../../store'

interface IProps {
  currentTotal: number
  totalListings: number
  activePage: number
  updateActivePage: (page: number) => void
}

class PaginationComponent extends Component<IProps> {
  public render() {
    const { activePage, currentTotal, totalListings } = this.props

    const totalPages = Math.ceil(totalListings / PAGE_COUNT)
    const componentsToShow = getPageNumbers(activePage, totalPages)
    const realPage = activePage - 1
    const startNumber = realPage * PAGE_COUNT
    const endNumber = startNumber + currentTotal
    return (
      <div>
        <Row className="no-gutters">
          <Col md={12}>
            <div className="pagination__wrapper">
              <Pagination
                aria-label="Page navigation example"
                className="justify-content-center"
              >
                <PaginationItem disabled={activePage === 1 ? true : false}>
                  <PaginationLink
                    previous={true}
                    onClick={this.setPage.bind(this, activePage - 1)}
                    className="page-link-prev"
                  >
                    <KeyboardArrowLeft />
                  </PaginationLink>
                </PaginationItem>
                {componentsToShow.map((e: number, i: number) => {
                  if (e !== -1) {
                    return (
                      <PaginationItem
                        key={i}
                        active={e === activePage ? true : false}
                      >
                        <PaginationLink onClick={this.setPage.bind(this, e)}>
                          {e}
                        </PaginationLink>
                      </PaginationItem>
                    )
                  } else {
                    return (
                      <PaginationItem key={i}>
                        <PaginationLink>...</PaginationLink>
                      </PaginationItem>
                    )
                  }
                })}
                <PaginationItem
                  disabled={
                    activePage === totalPages || totalPages === 0 ? true : false
                  }
                >
                  <PaginationLink
                    next={true}
                    onClick={this.setPage.bind(this, activePage + 1)}
                    className="page-link-next"
                  >
                    <KeyboardArrowRight />
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
          </Col>
        </Row>
        <div className="pagination__text">{`${startNumber}-${endNumber} of ${totalListings} Results`}</div>
      </div>
    )
  }

  private setPage = (page: number) => {
    this.props.updateActivePage(page)
    animateScroll.scrollToTop({
      containerId: 'sidebar',
      delay: 0,
      duration: 800,
      offset: -130,
      smooth: 'easeInOutQuart',
    })
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    activePage: state.search.currentPageNumber,
    currentTotal: state.details.currentSelection.length,
    totalListings: state.search.boundslistings.length,
  }
}

export default connect(mapStateToProps, {
  updateActivePage,
})(PaginationComponent)
