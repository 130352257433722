import { ISellerTypeID } from 'mhbo-js'
import queryString from 'query-string'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form } from 'reactstrap'
import { listingsSearch, updateActivePage } from '../../store/search/actions'
import AdComponent from './AdComponent'
import { ISearchQuery } from '../common-ui/interfaces'
import ListingsView from './ListingsView'
import SearchForm from './SearchForm'
import { updateQuery } from '../common-ui/helpers'
import { useAppDispatch } from '../../store/hooks'

const Sidebar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const query = queryString.parse(location.search) as ISearchQuery

  useEffect(() => {
    const decodedURI = decodeURIComponent(window.location.href)
    const { sellerTypeIds } = query
    if (decodedURI === window.location.href) {
      if (!sellerTypeIds)
        updateQuery({
          updates: {
            sellerTypeIds: [
              `${ISellerTypeID.Owner}`,
              `${ISellerTypeID.Repo}`,
              `${ISellerTypeID.Agent}`,
            ],
          },
          navigate,
          dispatch,
          listingsSearch,
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    dispatch(updateActivePage(1))
  }

  return (
    <div className="sidebar">
      <Form onSubmit={handleOnSubmit}>
        <SearchForm
          currentQuery={queryString.parse(location.search) as ISearchQuery}
        />
      </Form>

      <ListingsView />
    </div>
  )
}

export default Sidebar
