import { FunctionComponent } from 'react'
import { Breadcrumb } from '../../common-ui'
import { ISearchQuery } from '../../common-ui/interfaces'
import ListingHeader from './ListingHeader'
import { useAppSelector } from '../../../store/hooks'

interface IProps {
  currentQuery: ISearchQuery
}

const SearchForm: FunctionComponent<IProps> = ({ currentQuery }) => {
  const { entityType, listingTypeIds, location, isDealer } = currentQuery
  const currentLocation = location || ''
  const showTab = useAppSelector((state) => state.details.showTab)
  return (
    <>
      <Breadcrumb />
      {showTab === 0 ? (
        <ListingHeader
          entityType={entityType}
          listingTypeIds={listingTypeIds}
          isDealer={isDealer}
          currentLocation={currentLocation}
        />
      ) : null}
    </>
  )
}

export default SearchForm
