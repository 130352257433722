function getPageNumbers(currentPage: number, pages: number) {
  const componentsToShow = []
  if (pages >= 1) {
    componentsToShow.push(1)
  }
  if (pages >= 2) {
    componentsToShow.push(pages)
  }
  if (pages >= 3) {
    let pushing = 2
    let i = 0
    if (currentPage === 1) {
      while (pushing !== pages && i !== 3) {
        componentsToShow.push(pushing++)
        i++
      }
    } else if (currentPage === pages) {
      pushing = pages - 1
      i = 0
      while (pushing !== 1 && i !== 3) {
        componentsToShow.push(pushing--)
        i++
      }
    } else {
      componentsToShow.push(currentPage)
      let leftToPush = 2
      let reachedBottom = false
      let bottomost = -1
      let topmost = -1
      let reachedtop = false
      if (currentPage - 1 !== 1) {
        componentsToShow.push(currentPage - 1)
        bottomost = currentPage - 1
        leftToPush--
      } else {
        reachedBottom = true
      }
      if (currentPage + 1 !== pages) {
        componentsToShow.push(currentPage + 1)
        topmost = currentPage + 1
        leftToPush--
      } else {
        reachedtop = true
      }
      while (leftToPush !== 0) {
        if (!reachedBottom) {
          bottomost--
          if (bottomost > 1) {
            componentsToShow.push(bottomost--)
            leftToPush--
          } else {
            reachedBottom = true
          }
        } else if (!reachedtop) {
          topmost++
          if (topmost !== pages) {
            componentsToShow.push(topmost++)
            leftToPush--
          } else {
            reachedtop = true
          }
        } else {
          break
        }
      }
    }
  }
  componentsToShow.sort((a, b) => a - b) // For ascending sort
  if (componentsToShow.length > 4) {
    const first = componentsToShow.shift()
    const second = componentsToShow.shift()
    const last = componentsToShow.pop()
    const secondlast = componentsToShow.pop()
    if (first && second) {
      componentsToShow.unshift(second)
      if (second - 1 !== first) {
        componentsToShow.unshift(-1)
      }
      componentsToShow.unshift(first)
    }
    if (last && secondlast) {
      componentsToShow.push(secondlast)
      if (last - 1 !== secondlast) {
        componentsToShow.push(-1)
      }
      componentsToShow.push(last)
    }
  }
  return componentsToShow
}

export default getPageNumbers
