import { IListingsSearchParams } from '../../store/search/types'
import { ISearchQuery } from './interfaces'
import queryString from 'query-string'

interface IUpdateQueryProps {
  updates: ISearchQuery
  navigate: any
  dispatch: any
  listingsSearch: (arg_: IListingsSearchParams) => void
}

const updateQuery = ({
  updates,
  navigate,
  dispatch,
  listingsSearch,
}: IUpdateQueryProps) => {
  const oldParams = queryString.parse(window.location.search) as ISearchQuery

  let params = { ...oldParams, ...updates }
  const query = queryString.stringify(params)
  navigate(`${window.location.pathname}?${query}`, { replace: true })
  params.location = undefined
  dispatch(
    listingsSearch({
      params: {
        ...params,
        page: 1,
        pageCount: 500,
      },
    })
  )
}

export { updateQuery }
