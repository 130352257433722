import MHBO, { IEnvironment, IMHBOApiClient } from 'mhbo-js'
let environment: IEnvironment = IEnvironment.Staging

if (process.env.REACT_APP_API_ENV === IEnvironment.Development) {
  environment = IEnvironment.Development
}

if (process.env.REACT_APP_API_ENV === IEnvironment.Production) {
  environment = IEnvironment.Production
}

let apiClient: IMHBOApiClient | null = null

const createAPIClient = (token: string = '') => {
  apiClient = MHBO(
    {
      token,
    },
    environment
  )
}

createAPIClient()

export { apiClient }

export default createAPIClient
