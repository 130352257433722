import { connect } from 'react-redux'
import { updateSelectedDetail } from '../../store/details/actions'
import { removeSelectedDetail } from '../../store/details/slice'
import {
  listingsSearch,
  updateMapAxisAlignedBoundingBox,
  updateInitialSelectedLocation,
  updateSelectedLocation,
  updateBoundaryFilter,
} from '../../store/search/actions'
import {
  updateLatLng,
  updateRequeryStatus,
  updateTriangle,
  updateFirstLoad,
} from '../../store/search/slice'

import PropertiesMap from './PropertiesMap'
import { RootState } from '../../store'

const mapStateToProps = (state: RootState) => ({
  lastCenterLat: state.search.lastCenterLat,
  lastCenterLng: state.search.lastCenterLng,
  lastCenterRadius: state.search.lastCenterRadius,
  selectedLocation: state.search.selectedLocation,
  shouldRequery: state.search.shouldRequery,
  streetMapData: state.search.streetMapData,
  boundslistings: state.search.boundslistings,
  isFirstLoad: state.search.isFirstLoad,
  filtered: state.search.filtered,
  triangle: state.search.triangle,
})

const ConnectedMap = connect(mapStateToProps, {
  listingsSearch,
  removeSelectedDetail,
  updateMapAxisAlignedBoundingBox,
  updateSelectedDetail,
  updateInitialSelectedLocation,
  updateSelectedLocation,
  updateBoundaryFilter,
  updateLatLng,
  updateFirstLoad,
  updateRequeryStatus,
  updateTriangle,
})(PropertiesMap)

export default ConnectedMap
