// TODO show downard arrow in center
const Marker = (props: any) => {
  const { color, text, onMouseOver, onClick, addHover, key } = props
  return (
    <div
      key={key}
      className={`Group-7${addHover ? '-Hover' : ''}`}
      onMouseOver={onMouseOver}
      onClick={onClick}
      onTouchEnd={(e) => {
        e.stopPropagation()
      }}
    >
      <div className={`Rectangle-7-${color}`}>
        <span className="Lorem-Ipsum">{text}</span>
      </div>
      <div className={`arrow-down-${color}`}></div>
      <div className={`arrow-down-white`}></div>
    </div>
  )
}

export default Marker
