import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '..'
import { IFavorite } from 'mhbo-js'

const selectUserFavorites = (state: RootState) => state.user.favorites

const selectFavoriteId = (_: RootState, id: number) => id

export const getFavoriteId = createSelector(
  selectUserFavorites,
  selectFavoriteId,
  (favorites, id) => {
    const favorite = favorites.filter(
      (fav: IFavorite) => fav.watchableId === id
    )

    if (favorite.length === 1) {
      return favorite[0].id
    } else {
      return -1
    }
  }
)
