import Search from '@mui/icons-material/Search'
import queryString from 'query-string'
import { useState } from 'react'
import { FunctionComponent, useEffect, useRef } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import { Loader } from '@googlemaps/js-api-loader'
import GOOGLE_API from '../../constants/googleAPIConstants'
import { ISearchQuery } from './interfaces'
import createGTM from '../../helpers/gtmevent'
import { updateTypedLocation } from '../../store/search/actions'
import { useAppDispatch } from '../../store/hooks'
import { useNavigate } from 'react-router-dom'
import { removeSelectedDetail } from '../../store/details/slice'

const loader = new Loader(GOOGLE_API)

const MainSearchField: FunctionComponent = () => {
  const searchInput = useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [loaded, setLoaded] = useState(false)
  const query = queryString.parse(window.location.search) as ISearchQuery

  const [value, setValue] = useState(query.location || '')
  useEffect(() => {
    async function loadGoogle() {
      await loader.importLibrary('places')
      setLoaded(true)
      if (value) dispatch(updateTypedLocation(value))
    }
    loadGoogle()
  }, [])

  if (!loaded) return null

  return (
    <div className="container__mainFieldWrapper" id="location-search">
      <PlacesAutocomplete
        value={value}
        onChange={setValue}
        // tslint:disable-next-line: jsx-no-lambda
        onSelect={(address) => {
          searchInput.current!.blur()
          const newaddress = address
            .split(',')
            .map((e: string) => e.trim())
            .join(', ')
          createGTM({
            category: 'Search, top navigation',
            action: 'search',
            label: 'react search',
            value,
          })
          const oldQuery = queryString.parse(window.location.search)
          oldQuery.location = newaddress
          const query = queryString.stringify(oldQuery)
          navigate(`${window.location.pathname}?${query}`, { replace: true })
          dispatch(removeSelectedDetail())
          dispatch(updateTypedLocation(newaddress))
          setValue(newaddress)
        }}
        searchOptions={{
          componentRestrictions: { country: ['us', 'ca'] },
          types: ['(regions)'],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="container__searchFieldWrapper">
            <input
              {...getInputProps({
                className: 'mainSearchField',
                placeholder: 'Enter city, state, or ZIP CODE',
              })}
              type="search"
              ref={searchInput}
            />
            <div className="mainSearchField__autocompleteContainer">
              {loading && (
                <div className="mainSearchField__suggestion_item">
                  Loading...
                </div>
              )}
              {suggestions.map((suggestion: any) => {
                const className = suggestion.active
                  ? 'mainSearchField__suggestion_item_active'
                  : 'mainSearchField__suggestion_item'
                return (
                  // tslint:disable-next-line:jsx-key
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                    key={suggestion.placeId} // must be after `getSuggestionItemProps` remove after library updates
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
              {suggestions.length === 0 &&
                !loading &&
                document.activeElement === searchInput.current &&
                value.length > 0 && (
                  <div className="mainSearchField__suggestion_item">
                    No Results.
                  </div>
                )}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

      <button
        className="mainSearchField__searchButton"
        type="submit"
        onClick={() => {
          dispatch(updateTypedLocation(value))
        }}
      >
        <Search />
      </button>
    </div>
  )
}

export default MainSearchField
