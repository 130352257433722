import {
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { ISellerTypeID } from 'mhbo-js'
import { FunctionComponent } from 'react'
import MarkerIcon from '../common-ui/MarkerIcon'
import { IPropertyDropdownProps } from './interfaces'
import BootstrapInput from './BootstrapInput'
import createGTM from '../../helpers/gtmevent'
const valuetoText = (value: number) => {
  if (value === 0) return 'All Types'
  if (value === 1) return 'For Sale'
  if (value === 2) return 'For Rent'
  if (value === 3) return 'Community'
  if (value === 4) return 'Dealer'
}

const PropertyDropdown: FunctionComponent<IPropertyDropdownProps> = ({
  updateQuery,
  entityType: initialEntityType = '',
  listingTypeIds: initialListingTypeIds = [],
  isDealer = false,
}) => {
  const [open, setOpen] = useState(false)

  const handleChange = (event: SelectChangeEvent<number>) => {
    const listingType = event.target.value as number
    handleListItemClick(listingType)
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleOpen = () => {
    if (isMobile) {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleListItemClick = (value: number) => {
    handleUpdate(value)
    handleClose()
  }

  const value = (
    entityType: string,
    listingTypeIds: string[],
    isDealerType: boolean
  ) => {
    let type = 0
    if (entityType && entityType === '2') {
      if (isDealerType) {
        type = 4
      } else {
        type = 3
      }
    } else if (entityType && entityType === '1') {
      type = 1
      if (listingTypeIds && listingTypeIds.includes('2')) {
        type = 2
      }
    }
    return type
  }
  const handleUpdate = (listingType: number) => {
    if (listingType !== 0)
      createGTM({
        category: `Filtering tool option #${listingType},${
          listingType === 1 ? ' All Types,' : ''
        } drop-down`,
        action: `select "${valuetoText(listingType)}"`,
        label: `select "${valuetoText(listingType)}"`,
      })

    let entityType = ''
    let listingTypeIds: string[] = []
    let isDealerType = 'false'
    if (listingType === 1) {
      entityType = '1'
      listingTypeIds = ['1']
    } else if (listingType === 2) {
      entityType = '1'
      listingTypeIds = ['2']
    } else if (listingType === 3) {
      entityType = '2'
    } else if (listingType === 4) {
      entityType = '2'
      isDealerType = 'true'
    }
    updateQuery({
      ageRestrictionType: [],
      entityType,
      isDealer: isDealerType,
      isMhrv: '',
      isResidentOwned: '',
      isTinyHouseCommunity: '',
      listingTypeIds,
      locationStatusId: [],
      maxPrice: '',
      minPrice: '',
      numBathrooms: [],
      numBedrooms: [],
      sellerTypeIds: [
        `${ISellerTypeID.Owner}`,
        `${ISellerTypeID.Repo}`,
        `${ISellerTypeID.Agent}`,
      ],
      withPetFriendly: '',
    })
  }

  const colorToNumber = ['none', '#22863b', '#e2610a', '#91278f', '#b20404']

  return (
    <div className="propertyDropdown__wrapper_inner">
      <FormControl className="filterPanel__formControl">
        <Select
          value={value(initialEntityType, initialListingTypeIds, isDealer)}
          onChange={handleChange}
          // tslint:disable-next-line: jsx-no-lambda
          IconComponent={() => (
            <KeyboardArrowDown className="filterPanel__iconStyle" />
          )}
          onClick={handleOpen}
          inputProps={{
            readOnly: isMobile,
          }}
          input={BootstrapInput}
        >
          <MenuItem value={0} className="propertyDropdown__menuItem">
            <MarkerIcon color={colorToNumber[0]} /> {valuetoText(0)}
          </MenuItem>
          <Divider variant="middle" color="#d8d8d8" />
          <MenuItem value={1} className="propertyDropdown__menuItem">
            <MarkerIcon color={colorToNumber[1]} /> {valuetoText(1)}
          </MenuItem>
          <Divider variant="middle" color="#d8d8d8" />
          <MenuItem value={2} className="propertyDropdown__menuItem">
            <MarkerIcon color={colorToNumber[2]} />
            {valuetoText(2)}
          </MenuItem>
          <Divider variant="middle" color="#d8d8d8" />
          <MenuItem value={3} className="propertyDropdown__menuItem">
            <MarkerIcon color={colorToNumber[3]} />
            {valuetoText(3)}
          </MenuItem>
          <Divider variant="middle" color="#d8d8d8" />
          <MenuItem value={4} className="propertyDropdown__menuItem">
            <MarkerIcon color={colorToNumber[4]} />
            {valuetoText(4)}
          </MenuItem>
        </Select>
        {isMobile && (
          <Dialog open={open} onClose={handleClose} fullScreen>
            <DialogTitle>Property Price</DialogTitle>
            <List>
              {Array.from({ length: 5 }, (_, index) => index).map((e) => (
                <>
                  <ListItem
                    value={e}
                    className="propertyDropdown__menuItem"
                    onClick={() => handleListItemClick(e)}
                  >
                    <MarkerIcon color={colorToNumber[e]} />
                    {valuetoText(e)}
                  </ListItem>
                  {e !== 4 ? (
                    <Divider variant="middle" color="#d8d8d8" />
                  ) : null}
                </>
              ))}
            </List>
          </Dialog>
        )}
      </FormControl>
    </div>
  )
}

export default PropertyDropdown
