import {
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { FunctionComponent } from 'react'
import {
  pricingOptionsBuy,
  pricingOptionsCommunities,
  pricingOptionsRent,
} from '../../constants/pricingOptionConstants'
import { ISearchQuery, ISecondaryDropdownProps } from './interfaces'
import BootstrapInput from './BootstrapInput'
import createGTM from '../../helpers/gtmevent'

const SecondaryDropdown: FunctionComponent<ISecondaryDropdownProps> = ({
  updateQuery,
  entityType: initialEntityType = '',
  listingTypeIds: initialListingTypeIds = [],
  isDealer = false,
  currentQuery,
}) => {
  const [open, setOpen] = useState(false)

  const handleChange = (event: SelectChangeEvent<string>) => {
    const listingType = parseInt(event.target.value) as number
    handleListItemClick(listingType)
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleOpen = () => {
    if (isMobile) {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleListItemClick = (value: number) => {
    handleUpdate(value)
    handleClose()
  }

  /**
   * Retrieves the current price range in the search query.
   * @returns {string} The current value of the price range filter if it exists.
   */
  const getCheckedOption = ({
    ageRestrictionType,
    isResidentOwned,
    withPetFriendly,
    entityType,
    minPrice,
    maxPrice,
    listingTypeIds,
  }: ISearchQuery): string => {
    if (entityType === '1') {
      if (minPrice && maxPrice && listingTypeIds) {
        if (listingTypeIds.includes('1')) {
          return pricingOptionsBuy.values.filter(
            (e) => e.value === `${minPrice},${maxPrice}`
          )[0].id
        }
        return pricingOptionsRent.values.filter(
          (e) => e.value === `${minPrice},${maxPrice}`
        )[0].id
      }
    }
    if (entityType === '2') {
      if (ageRestrictionType) {
        if (ageRestrictionType.includes('2')) {
          return '9'
        }
        if (ageRestrictionType.includes('1')) {
          return '10'
        }
      }
      if (isResidentOwned && isResidentOwned === 'true') {
        return '11'
      }
      if (withPetFriendly && withPetFriendly === 'true') {
        return '12'
      }
    }
    return '0'
  }

  const optionsObject = (
    entityType: string,
    listingTypeIds: string[],
    isDealerType: boolean
  ) => {
    let pricingOptions = null
    if (entityType === '2' && !isDealerType) {
      pricingOptions = pricingOptionsCommunities
    }
    if (entityType === '1') {
      if (listingTypeIds) {
        if (listingTypeIds.includes('1') || listingTypeIds.includes('3')) {
          pricingOptions = pricingOptionsBuy
        } else {
          pricingOptions = pricingOptionsRent
        }
      }
    }
    return pricingOptions
  }

  const handleUpdate = (checkValue: number) => {
    const queryUpdate: ISearchQuery = {
      ageRestrictionType: [],
      isResidentOwned: '',
      maxPrice: '',
      minPrice: '',
      withPetFriendly: '',
    }
    let Update
    if (checkValue === 0) {
      if (initialEntityType === '1') {
        // get label of selection

        createGTM({
          action: `select "Any Price"`,
          label: `select "Any Price"`,
        })
      }
      return updateQuery(queryUpdate)
    }
    if (checkValue >= 9) {
      Update = pricingOptionsCommunities.values.filter(
        (e) => parseFloat(e.id) === checkValue
      )[0]
      const { label, value: updateValue } = Update
      if (label === 'ROC') {
        queryUpdate.isResidentOwned = updateValue
      } else if (label === 'Pets OK') {
        queryUpdate.withPetFriendly = updateValue
      } else {
        queryUpdate.ageRestrictionType = [updateValue]
      }
    } else {
      if (checkValue <= 4) {
        Update = pricingOptionsRent.values.filter(
          (e) => parseFloat(e.id) === checkValue
        )[0]
      } else {
        Update = pricingOptionsBuy.values.filter(
          (e) => parseFloat(e.id) === checkValue
        )[0]
      }
      // check if for sale is selected

      const prices = Update.value.split(',')
      queryUpdate.minPrice = prices[0]
      queryUpdate.maxPrice = prices[1]
    }

    if (!isDealer) {
      // get label of selection

      createGTM({
        action: `select "${Update.label}"`,
        label: `select "${Update.label}"`,
      })
    }

    updateQuery(queryUpdate)
  }

  const renderValue = (e: number) => {
    const pricingoption = optionsObject(
      initialEntityType,
      initialListingTypeIds,
      isDealer
    )
    switch (e) {
      case 0:
        return pricingoption?.default
      case 1:
      case 2:
      case 3:
      case 4:
        return pricingoption!.values[e - 1].label
      default:
        return ''
    }
  }

  const pricingoption = optionsObject(
    initialEntityType,
    initialListingTypeIds,
    isDealer
  )
  if (pricingoption !== null) {
    return (
      <div className="propertyDropdown__wrapper_inner">
        <FormControl className="filterPanel__formControl">
          <Select
            value={getCheckedOption(currentQuery)}
            onChange={handleChange}
            onClick={handleOpen}
            // tslint:disable-next-line: jsx-no-lambda
            IconComponent={() => (
              <KeyboardArrowDown className="filterPanel__iconStyle" />
            )}
            inputProps={{
              readOnly: isMobile,
            }}
            input={BootstrapInput}
          >
            <MenuItem value={0} className="propertyDropdown__menuItem">
              {renderValue(0)}
            </MenuItem>
            <Divider variant="middle" color="#d8d8d8" />
            <MenuItem
              value={parseFloat(pricingoption.values[0].id)}
              className="propertyDropdown__menuItem"
            >
              {renderValue(1)}
            </MenuItem>
            <Divider variant="middle" color="#d8d8d8" />
            <MenuItem
              value={parseFloat(pricingoption.values[1].id)}
              className="propertyDropdown__menuItem"
            >
              {renderValue(2)}
            </MenuItem>
            <Divider variant="middle" color="#d8d8d8" />
            <MenuItem
              value={parseFloat(pricingoption.values[2].id)}
              className="propertyDropdown__menuItem"
            >
              {renderValue(3)}
            </MenuItem>
            <Divider variant="middle" color="#d8d8d8" />
            <MenuItem
              value={parseFloat(pricingoption.values[3].id)}
              className="propertyDropdown__menuItem"
            >
              {renderValue(4)}
            </MenuItem>
          </Select>

          {isMobile && (
            <Dialog open={open} onClose={handleClose} fullScreen>
              <DialogTitle>Property Price</DialogTitle>
              <List>
                {Array.from({ length: 5 }, (_, index) => index).map((e) => (
                  <>
                    <ListItem
                      value={e}
                      className="propertyDropdown__menuItem"
                      onClick={() =>
                        handleListItemClick(
                          e === 0
                            ? 0
                            : parseFloat(pricingoption.values[e - 1].id)
                        )
                      }
                    >
                      {renderValue(e)}
                    </ListItem>
                    {e !== 4 ? (
                      <Divider variant="middle" color="#d8d8d8" />
                    ) : null}
                  </>
                ))}
              </List>
            </Dialog>
          )}
        </FormControl>
      </div>
    )
  }
  return null
}

export default SecondaryDropdown
