import Close from '@mui/icons-material/Close'
import { FunctionComponent } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { useState } from 'react'

const ModalVersion: FunctionComponent = () => {
  const [appVersionVisible, setAppVersionVisible] = useState(false)
  window.document.addEventListener('keydown', (event) => {
    if (event.ctrlKey && event.code === 'KeyM') {
      setAppVersionVisible(!appVersionVisible)
    }
  })

  return (
    <Modal isOpen={appVersionVisible} backdrop={true} centered={true} size="lg">
      <ModalBody>
        <div>
          {process.env.REACT_APP_GIT_ENV}
          <Close
            className="modalSearch__close"
            onClick={() => {
              setAppVersionVisible(false)
            }}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalVersion
