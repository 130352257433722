import {
  FormControl,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
} from '@mui/material'

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import React, { Suspense } from 'react'
import { FunctionComponent, useState } from 'react'
import BootstrapInput from './BootstrapInput'
import { IMoreDropdownProps } from './interfaces'
const SearchFilters = React.lazy(
  () => import('../../components/Sidebar/SearchFilters')
)

const MoreDropdown: FunctionComponent<IMoreDropdownProps> = ({
  updateQuery,
  entityType: initialEntityType = '',
  listingTypeIds: initialListingTypeIds = [],
  isDealer = false,
  query,
}) => {
  const [filterOpen, setFilterOpen] = useState<boolean>(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const value = (
    entityType: string,
    listingTypeIds: string[],
    isDealerType: boolean
  ) => {
    if (entityType && entityType === '2') {
      if (!isDealerType) {
        return true
      }
    } else if (entityType && entityType === '1') {
      if (listingTypeIds && listingTypeIds.includes('1')) {
        return true
      }
    }
    return false
  }
  if (value(initialEntityType, initialListingTypeIds, isDealer)) {
    return (
      <div className="propertyDropdown__wrapper_inner">
        <FormControl className="filterPanel__formControl">
          <Select
            value={0}
            open={false}
            // tslint:disable-next-line: jsx-no-lambda
            IconComponent={() => (
              <KeyboardArrowDown className="filterPanel__iconStyle" />
            )}
            // tslint:disable-next-line: jsx-no-lambda
            onOpen={() => {
              setFilterOpen(!filterOpen)
            }}
            input={BootstrapInput}
          >
            <MenuItem value={0} className="propertyDropdown__menuItem">
              More
            </MenuItem>
          </Select>
        </FormControl>
        {filterOpen ? (
          isMobile ? (
            <Dialog open fullScreen>
              <DialogTitle>Filters</DialogTitle>

              <Suspense fallback={null}>
                <SearchFilters
                  getQuery={query}
                  updateQuery={updateQuery}
                  // tslint:disable-next-line: jsx-no-lambda
                  toggleFilterDisplay={() => {
                    setFilterOpen(!filterOpen)
                  }}
                />
              </Suspense>
            </Dialog>
          ) : (
            <div className="searchFilters__cover">
              <Suspense fallback={null}>
                <SearchFilters
                  getQuery={query}
                  updateQuery={updateQuery}
                  // tslint:disable-next-line: jsx-no-lambda
                  toggleFilterDisplay={() => {
                    setFilterOpen(!filterOpen)
                  }}
                />
              </Suspense>
            </div>
          )
        ) : null}
      </div>
    )
  }
  return null
}

export default MoreDropdown
